@import '@mixins';

.error-container {
	background-color: @content-background;
	display: block;
	text-align: center;
	padding-top: 8px;
	padding-bottom: 8px;
	clear: both;
	margin: 0;

	.image-404 {
		width: 80%;
		margin: 0 auto;
	}

	p {
		font-size: 16px;
		line-height: 24px;
	}

	.red-ups {
		color: #ff380e;
		font-size: 36px;
		font-weight: bold;
		margin-top: 24px;
		margin-bottom: 24px;
	}

	a {
		.default-button();

		&:hover {
			text-decoration: none;
		}
	}

	.go-to-homepage {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	@media (min-width: @screen-sm-min) {
		margin-top: 32px;
	}

	@media (min-width: @screen-md-min) {
		width: @container-md;
		margin: 24px auto;
	}

	@media (min-width: @screen-lg-min) {
		width: @container-lg;
	}
}
