ul,
ol,
p {
  margin-bottom: 14px;
}
.error-container {
  background-color: #ffffff;
  display: block;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  clear: both;
  margin: 0;
}
.error-container .image-404 {
  width: 80%;
  margin: 0 auto;
}
.error-container p {
  font-size: 16px;
  line-height: 24px;
}
.error-container .red-ups {
  color: #ff380e;
  font-size: 36px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 24px;
}
.error-container a {
  line-height: inherit;
  padding: 4px 8px 4px 8px;
  position: relative;
  background-color: #da532c;
  border: 0;
  border-radius: 0;
  color: #ffffff;
  font-weight: bold;
}
.error-container a:visited {
  background-color: #da532c;
  color: #ffffff;
}
.error-container a:hover,
.error-container a:active {
  background-color: #b4411f;
}
.error-container a:hover {
  text-decoration: none;
}
.error-container .go-to-homepage {
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .error-container {
    margin-top: 32px;
  }
}
@media (min-width: 992px) {
  .error-container {
    width: 970px;
    margin: 24px auto;
  }
}
@media (min-width: 1200px) {
  .error-container {
    width: 1170px;
  }
}
